import axios from "axios";
import swal from "sweetalert2";
var http = null;

class WebService {
  constructor() {
    http = axios.create({});
  }

  getAPI({
    _action,
    _body,
    _buttonId,
    _hideLoader,
    _isHideErrorPopup
  }) {
    let params = new URLSearchParams();
    for (let key in _body) {
      params.set(key, _body[key]);
    }

    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }

    return http
      .get(this.getUrl() + _action, {
        params: params,
        headers: this.getHeader(),
        timeout: 90000,
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        if (_isHideErrorPopup) {
          return;
        }
        return this._errorHandler(e);
      });
  }

  postAPI({
    _action,
    _body,
    _buttonId,
    _hideLoader
  }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    return http
      .post(this.getUrl() + _action, JSON.stringify(_body), {
        headers: this.getHeader(),
      })
      .then((response) => {
        this.removeLoader(_buttonId);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  patchAPI({
    _action,
    _body,
    _buttonId,
    _hideLoader
  }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }

    return http
      .patch(this.getUrl() + _action, JSON.stringify(_body), {
        headers: this.getHeader(),
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  putAPI({
    _action,
    _body,
    _buttonId,
    _hideLoader
  }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    return http
      .put(this.getUrl() + _action, JSON.stringify(_body), {
        headers: this.getHeader(),
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  deleteAPI({
    _action,
    _buttonId,
    _hideLoader
  }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    return http
      .delete(this.getUrl() + _action, {
        headers: this.getHeader()
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  formDataPost({
    _action,
    _body,
    _buttonId,
    _hideLoader
  }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    var headers = {};
    let params = new URLSearchParams();
    for (let key in _body) {
      params.set(key, _body[key]);
    }

    return http
      .post(this.getUrl() + _action, params, null, {
        headers: headers,
      })
      .then((response) => {
        localStorage.setItem("access_token", response.data.access_token);
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._loginErrorHandler(e);
      });
  }

  fileDataAPI({
    _action,
    _file,
    _key,
    _body,
    _buttonId,
    _hideLoader
  }) {
    if (!_key) {
      _key = "file";
    }
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    let formData = new FormData();
    if (_file) {
      formData.append(_key, _file);
    }

    for (let key in _body) {
      formData.append(key, _body[key]);
    }

    return http
      .post(this.getUrl() + _action, formData, {
        headers: this.addHeaderForMultipart(),
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  _loginErrorHandler(error) {
    if (error.response != null) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 400 || error.status === 404) {
      errorMessage =
        (error && error.data && error.data.message) ||
        "Invalid Username or Password";
    } else if (error.status === 500) {
      errorMessage = "Server Error";
    } else {
      errorMessage =
        (error & error.data && error.data.message) || "Bad Response";
    }
    // alert(error.data.message)
    swal.fire({
      title: "Error",
      icon: "error",
      text: errorMessage,
      showConfirmButton: false,
      showCloseButton: true,
    });

    throw new Error(errorMessage);
  }

  _errorHandler(error) {
    if (error.response != null) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      errorMessage =
        (error && error.data && error.data.message) || "Bad Response";
      localStorage.removeItem("access_token");
      // this.logout()
    } else if (error.status === 500) {
      errorMessage = "Server Error";
    } else {
      errorMessage =
        (error && error.data && error.data.message) || "Bad Response";
    }
    // alert(error.data.message)
    swal.fire({
      title: "Error",
      icon: "error",
      text: errorMessage,
      showConfirmButton: false,
      showCloseButton: true,
    });

    throw new Error(errorMessage);
  }

  logout() {
    localStorage.clear();
    window.location.href = "/login";
  }

  getHeader() {
    var headers = {};
    headers["Content-Type"] = "application/json";
    if (localStorage.getItem("access_token")) {
      headers["Authorization"] =
        "Bearer " + localStorage.getItem("access_token");
    }
    return headers;
  }

  addHeaderForMultipart() {
    if (localStorage.getItem("access_token")) {
      var headers = {};
      headers["Authorization"] =
        "Bearer " + localStorage.getItem("access_token");
      return headers;
    } else {
      // logout app
    }
  }

  addLoader(id) {
    if (id) {
      var button = document.getElementById(id);
      if (button.disabled) {
        return true;
      }
      button.disabled = true;
      var loader = document.createElement("i");
      loader.className = "fa fa-spinner fa-spin";
      loader.style = "margin-right:5px";
      button.prepend(loader);
      return false;
    }
  }

  removeLoader(id) {
    if (id) {
      var button = document.getElementById(id);
      button.disabled = false;
      button.removeChild(button.childNodes[0]);
    }
  }

  getBaseUrl() {
    return http;
  }

  uploadImageAPI({
    _action,
    _file,
    _key,
    _body,
    _buttonId,
    _hideLoader
  }) {
    if (!_key) {
      _key = 'file'
    }
    if (this.addLoader(_buttonId, _hideLoader)) {
      return
    }
    let formData = new FormData()
    if (_file) {
      formData.append(_key, _file)
    }
    for (let key in _body) {
      formData.append(key, _body[key])
    }

    return http
      .post(this.getUrl() + _action, formData, {
        headers: this.addHeaderForMultipart()
      })
      .then(response => {
        this.removeLoader(_buttonId, _hideLoader)
        return response.data
      })
      .catch(e => {
        this.removeLoader(_buttonId, _hideLoader)
        return this._errorHandler(e)
      })
  }

  getUrl() {
    return "https://devapp.maltiplai.com/admin"
    // return "https://app.maltiplai.com/admin"
  }
}



export default new WebService();